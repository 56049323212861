@import './_vars.scss';
@import './typography.scss';

// @import '~bootstrap/scss/bootstrap'; // To load all of bootstrap (dont)
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Optional
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
// @import '~bootstrap/scss/card';
// @import '~bootstrap/scss/pagination';
// @import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/utilities';
// @import '~bootstrap/scss/list-group';
// @import '~bootstrap/scss/root';
// @import '~bootstrap/scss/images';
//@import "~bootstrap/scss/code";
// @import '~bootstrap/scss/tables';
//@import "~bootstrap/scss/custom-forms";
//@import "~bootstrap/scss/breadcrumb";
@import '~bootstrap/scss/jumbotron';
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
// @import '~bootstrap/scss/popover';
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/print";

body {
  overflow-x: hidden;
}
// utils

.d-grid {
  display: grid !important;
}

.hide-mobile {
  display: none;
  @media screen and (min-width: 768px) {
    display: inherit;
  }
}

// /utils

// colour classes

.bg-red {
  background-color: $red;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-neutral {
  background-color: $neutral;
}

.color-dark {
  color: $dark;
}

.color-light {
  color: $light;
}

// /colour classes

// layout classes

.centred-hed {
  text-align: center;
}

// /layout classes

.pageBody {
  margin-top: 76px;
  min-height: 60vh;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
}

.btn-c2a {
  font-family: $fontstack-hed;
  padding: 10px;
  font-size: 12px;
  line-height: 114%;
  background-color: $c2a;
  color: $dark;
  border: 2px solid $c2a;
  &:hover {
    background-color: rgba($color: $c2a, $alpha: 0.2);
    color: $c2a;
    border-color: $c2a;
    cursor: pointer;
  }
}

.btn-dark-on-hover:hover {
  border: thin solid $dark;
}

.facebook {
  background: #3b5998;
}

.twitter {
  background: #1da1f2;
}

.whatsapp {
  background: #25d366;
}

.row {
  padding: 1rem;
}

.jumbotron {
  margin-top: -76px;
}

.nominateAddressControl {
  display: inline-block;
}

.navbar-dark {
  &.transparent {
    background-color: transparent;
    @media (max-width: 768px) {
      &.open {
        background-color: $primary;
      }
    }
  }
  @media (max-width: 768px) {
    text-align: center;
    .nav-item a.nav-link {
      font-size: 1.6rem;
    }
    .navbar-nav {
      padding: 1rem 0;
    }
  }
  .navbar-nav .nav-link {
    font-weight: 700;
    color: $light;
    font-size: 1.2rem;
    &.btn-light {
      color: $primary;
      min-width: 100px;
    }
  }
}

// form

.heroForm {
  margin-top: 10px;
  vertical-align: top;
}

.legal {
  font-size: 11px !important;
}

.email-signup {
  flex-direction: column;

  @media (min-width: 400px) {
    flex-direction: row;
  }

  .initial-btn {
    @media (min-width: 400px) {
      margin-left: 15px;
    }
  } 
}

.form-group, #form-thanks {
  color: $light;
}

.form-check-label {
  display: inline;
}

// /form

.larger-text {
  p, .form-check-label {
    font-size: $bodyTextSize;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  h1,
  strong {
    font-size: 2.5rem;
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
}

// Candidates

.candidateExcerpt > a > p {
  margin-bottom: .1em;
}

.candidateExcerpt > p {
  font-size: 1rem !important;
  margin-bottom: .8em;
}

.candidateExcerpt > span {
  display: inline-block;
  font-family: monotype-grotesque,sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: -.045em !important;
  line-height: 120% !important;
}

.candidateHeaderFlex {
  display: flex;
  align-items: end;
  margin-bottom: .5rem;
  gap: 1rem;
}

.candidateHeader {
  margin-bottom: 0;
}

.candidateNumber {
  font-family: monotype-grotesque,sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: -.045em !important;
  line-height: 120% !important;
  margin-bottom: .33em;
}

@media screen and (max-width: 990px) {
  .candidateHeaderFlex {
    flex-direction: column;
    align-items: start;
    gap: .7rem;
  }
}
