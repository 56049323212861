@import '../../_vars';
@import '../../components/PolicyCarousel/PolicyCarousel.scss';
@import '../../components/CandidateExcerpt/CandidateExcerpt.scss';

#landing {

  #landing-bg {
    background-image: url('/img/landing_bg.png');
    background-size: cover;
    background-color: $neutral;
    background-repeat: no-repeat;
    background-position: center 60%;
    min-height: 700px;
  }

  .jumbotron {
    background: none;
    text-align: left;
    color: $light;
    flex-direction: column;
    justify-content: flex-end;
    a {
      color: $light;
    }
    
    .navbar {
      align-self: flex-start;
      img {
        height: 100px;
        @media screen and (min-width: 768px){
          height: 150px;
        }
      }
    }

    .hero.container-fluid {
      display: grid;
      text-align: center;
      grid-auto-rows: min-content;
      h1 {
        align-self: flex-end;
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
        }
      }

      iframe {
        $size: 450px;
        align-self: flex-end;
        justify-self: center;
        height: 100vw;
        width: 92vw;
        max-width: $size;
        max-height: $size;
        border: none;
      }
      h1, h5, .formParent {
        align-self: flex-end;
        justify-self: center;
      }
    }


  }



  .hero span::before {
    content: "\A";
    white-space: pre;
    @media screen and (min-width: 768px) {
      content: unset;
      white-space: unset;
      display: none;
    }
  }

  .formParent {
    // bit freaky cos of Sass's use of `min()` 
    // https://sass-lang.com/documentation/syntax/special-functions#min-and-max
    // 
    // fix used
    // https://github.com/sass/libsass/issues/2701#issuecomment-611721797
    max-width: unquote("min(90vw, 500px)");
    align-self: flex-end;
  }

  .supporters {
    text-align: center;
    img {
      width: 60%;
    }
  }

  .promptbanner-mb {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .about {
    
    
    background-image: url("/img/Whatwestandfor_IMG.png");
    background-size: 120%;
    background-position-x: center;
    background-position-y: 110%;
    background-repeat: no-repeat;
    padding-bottom: 60vw;


    p, h1, h2, h3 {
      position: relative;
      z-index: 1;
      top: 0;
    }

    strong {
      background-color: yellow;
      display: inline-block;
      padding: 5px;
      margin: unset;
    }

    @media screen and (min-width: 375px) {
      background-position-y: 112% ;
    }

    @media screen and (min-width: 475px) {
      background-position-y: 105% ;
      background-size: 90%;
    }
    
    @media screen and (min-width: 575px) {
      background-position-y: 105% ;
    }
    @media screen and (min-width: 768px) {
      background-position-y: 110%;
      background-size: 90%;
    }
    @media screen and (min-width: 992px) {
      background-position-y: center;
      background-position-x: 120%;
    }
    @media screen and (min-width: 1200px) {
      background-position-y: center;
      background-position-x: 95%;
      background-size: 120%;
    }
    
    @media screen and (min-width: 992px) {
      $pad: 6vh;
      padding-top: $pad;
      padding-bottom: $pad;
      background-size: 48%;
      // padding: 100px 0px 100px 0px;
    }
  }

  .candidates {
    color: $light;
    &.bg-dark {
      hr {
        border: 2px solid white;
        margin-top: unset;
        margin-bottom: unset;
        margin: 40px;
      }
    }
  }
}

.c2aSection {
  .row {
    flex-direction: column-reverse;
    @media screen and (min-width: 768px) {
      flex-direction: initial;
    }
  }
  .d-grid {
    height: 100%;
    justify-content: center;
    align-content: center;
  }
  img {
    max-width: 175px;
    object-fit: cover;
    grid-row: 0;
  }
}

.collapse-section {
  h2 {
    text-align: center;
  }
  .d-flex {
    flex-direction: column;
    align-items: center;
  }
  .btn {
    @media screen and (min-width: 525px) {
      width: fit-content;
    }
  }
}

.bg-neutral a {
  color: $dark;
  text-decoration: underline;
  &:hover {
    color: $dark;
  }
  &.btn:hover {
    color: $light;
  }
}

.form-checkbox-page {
  padding: 20px;
  border-radius: 5px;
  background: $light;
  * {
    color: $dark;
  }
  display: grid !important;
  justify-content: center !important;
  align-content: center !important;
}

.form-check {

  display: grid !important;
  // grid-template-columns: 50px 1fr;
  margin: 15px 0px;
  input {
    justify-self: center;
  }
  label {
    @extend .type-body-1;
    justify-self: flex-start;
    text-align: left;
  }
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  // requires sass vars, set above
  input[type='checkbox'], input[type='radio'] {
    --active: #{$red};
    --active-inner: #{$red};
    --focus: 2px #{$red};
    --border: #{$red};
    --border-hover: #{$red};
    --background: #{$light};

    -webkit-appearance: none;
    -moz-appearance: none;

    height: 21px;
    display: inline-block;
    position: relative;
    margin: 0;
    cursor: pointer;
    transition: background .3s, border-color .3s, box-shadow .2s;
    outline: none;

    &:after {
      content: '';
      display: block;
      left: 2px;
      top: 2px;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: #{$light};
      --bc: #{$red};
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
  }

  input[type='checkbox'] {
    border: 2px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    width: 21px;

    border-radius: 2px;
    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 4px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }

  input[type='radio'] {
    border: 2px solid var(--bc, var(--border));
    background: var(--background);
    width: 17px;
    height: 17px;
    
    border-radius: 50%;
    &:after {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: var(--active);
      opacity: 0;
    }
    &:checked {
      --s: .5;
    }
  }
}

p a {
  color: $red;
  &:hover {
    color: $red;
  }
}
