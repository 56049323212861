@import '../../vars';
@import '../../typography.scss';

.simplePage {
  .backLink {
    @extend .type-label-1;
    display: block;
    color: $red;
    width: 350px;
    float: left; 
  }
  .d-flex {
    justify-content: center;
    @media screen and (min-width: 768px) {
      justify-content: flex-end;
    }
  }
  .img-simplePage {
    max-width: 350px;
    object-fit: cover;
  }
  .handle>* {
    @extend .type-label-1;
    color: $dark;
  }
}