$bodyTextSize: 1.3rem;

$light: #ffffff;
$neutral: #F8E9E5;
$dark: #272C2A;
$yellow: #EAFF00;
$red: #FF004D;

$primary: $light;
$secondary: $dark;
$c2a: $yellow;