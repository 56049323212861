@import url('https://fonts.googleapis.com/css?family=Antic+Didone|Montserrat:700');
@import '../../_vars';

$selection-width: 10%;
$panel-width: 27%;
$arrowWrapperWidth: 5%;
$card-width: 100% - ($arrowWrapperWidth + $selection-width + $panel-width);

section.wrapper {
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  position: relative;
  animation: fadeIn 500ms ease-in-out forwards;
}

.selectors {
  position: relative;
  top: 0;
  left: 0;
  width: $selection-width;
  background: $light;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .selector {
    border: 1px solid $secondary;
    background: $secondary;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out;
    &:hover,
    &.active {
      border: 1px solid $primary;
      background: $primary;
    }
  }
}

.wrapper {
  margin: 0px;
  width: 100%;
  height: 600px;
  background-size: cover;
  transition: background-image 500ms ease-in-out;
  display: flex;
}

.panel {
  width: $panel-width;
  display: flex;
  flex-direction: column;
  padding-top: 195px;
}

.policycard,
.photocard {
  width: $card-width;
}
.photocard {
  object-fit: cover;
  height: auto;
}
.panel {
  background-color: $light;
  opacity: 1;
  color: $primary;
  transition: background 500ms ease-in-out;
  padding-left: 20px;
  p {
    margin: 0px;
    padding: 10px;
    font-size: $bodyTextSize;
    font-weight: bold;
    font-style: italic;
  }
  h2.panel-header {
    margin: 0;
    align-self: center;
    font: {
      weight: bold;
      style: italic;
      size: $bodyTextSize * 3;
    }
  }
  .panelRow {
    display: flex;
    justify-content: space-between;
  }

  p.panel-button {
    display: none;
  }
}
.panel-button {
  cursor: pointer;
}
.arrowWrapper {
  width: $arrowWrapperWidth;
  display: flex;
  justify-content: center;
  background: white;
}
img.panel-button {
  height: 8vw;
  max-height: 60px;
  min-height: 40px;
  align-self: center;
}

.policyBoot {
  position: relative;
  color: $primary;
  background: $light;
  font-size: $bodyTextSize;
  padding: 0px;
  width: $card-width;
  z-index: -1;
  .container {
    max-width: 90%;
    margin: 0px;
    height: 100%;
    .row {
      height: 100%;
      div.col-sm-12.col-md-12 {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }
    }
  }
  @media (min-width: 768px) {
    animation: slideinleft 0.5s;
  }
}

@media (max-width: 1200px) {
  .wrapper {
    .panel {
      .panelRow {
        h2.panel-header {
          font-size: 2.7rem !important;
        }
      }
    }
  }
}
@media (max-width: 940px) {
  .wrapper {
    flex-direction: column;
    height: auto;
    .policyBoot {
      width: auto;
      min-height: 300px;
    }
  }
  .selectors {
    flex-direction: row;
    width: auto;
    height: $selection-width;
  }
  .panel {
    p {
      padding-bottom: 15px;
    }
    width: auto;
    text-align: center;
    height: 215px;
    justify-content: flex-start;
    padding-top: 0px;
    padding-left: 0px;
    .panelRow {
      height: 100%;
      flex-direction: column;
      h2.panel-header {
        font-size: $bodyTextSize * 2;
        align-self: center;
      }
      p.panel-button {
        justify-self: center;
        align-self: center;
        display: block;
      }
    }
  }
  img.panel-button {
    display: none;
  }

  .photocard {
    min-height: 300px;
    max-height: 50vh;
    width: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideinleft {
  from {
    margin-left: -200px;
  }
  to {
    margin-left: 0px;
  }
}
