@import '../../_vars';
@import '../../typography.scss';

nav {
  z-index: 9999;
}

.navBackground {
  background-color: $red;
  // background-image: -webkit-gradient(
  //   linear,
  //   left top,
  //   left bottom,
  //   from(rgba(0, 0, 0, 0.43)),
  //   to(transparent)
  // );
  // background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.43), transparent);
  position: absolute !important;
  left: 0px;
  right: 0px;
  top: 0px;
}

.navbar-toggler {
  border-color: rgba(256, 256, 256, 0) !important;
}

.navbar-toggler-icon {
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/5/59/Hamburger_icon_white.svg') !important;
}

.pageBody {
  margin-top: 76px;
  min-height: 60vh;
}

.smsFbVid {
  overflow: hidden;
  display: block;
  min-height: 90vw;
  margin: 0 auto;
  @media (min-width: 768px) {
    min-height: 500px;
    width: 500px;
  }
}

.footer {
  background-color: $red;
  .col {
    padding: 0px;
  }
  @media (min-width: 992px) {
    // margin-top: -150px;
  }
}

.footer-list {
  color: $light;
  list-style: none;
  li {
    @extend .type-body-3;
    br {
      height: 1rem;
    }
    strong {
      @extend .type-label-1;
    }
  }
}