$fontstack-body: monotype-grotesque, sans-serif;
$fontstack-hed: monotype-grotesque-extended, sans-serif;

$letterspacing-lg: -0.07em;
$letterspacing-sm: -0.045em;

@mixin font-factory($fontstack, $weight: 400) {
  font-family: $fontstack !important; 
  font-style: normal !important;
  font-weight: $weight !important;
}

.type-header-1 {
  @include font-factory($fontstack-hed,700);
  font-size: 48px !important;
  line-height: 114% !important;
  letter-spacing: $letterspacing-lg !important;
}

.type-header-2 {
  @include font-factory($fontstack-hed, 700);
  font-size: 30px !important;
  line-height: 114% !important;
  line-height: 114%;
  letter-spacing: $letterspacing-lg !important;
}

// candidate bio heds
.type-header-3 {
  @include font-factory($fontstack-hed, 700);
  font-size: 24px !important;
  line-height: 124% !important;
  letter-spacing: $letterspacing-sm !important;
}

// read more
.type-header-4{
  @include font-factory($fontstack-hed, 700);
  font-size: 18px !important;
  line-height: 114% !important;
  letter-spacing: $letterspacing-sm !important;
} 

// NOTE: I suspect most of these "!important"s are actually pointless,
// but for now I'm just removing the one that's been giving me trouble - Freya, 08/03/22
.type-body-1 {
  @include font-factory($fontstack-body);
  font-size: 21px;
  line-height: 126.5% !important;
  letter-spacing: $letterspacing-sm !important;
}

.type-body-2 {
  @include font-factory($fontstack-body);
  font-size: 15px !important;
  line-height: 124% !important;
  letter-spacing: $letterspacing-sm !important;
}

// footer
.type-body-3 {
  @include font-factory($fontstack-body);
  font-size: 14px !important;
  line-height: 125.5% !important;
  letter-spacing: $letterspacing-sm !important;
}

.type-label-1 {
  @include font-factory($fontstack-body, 700);
  font-size: 14px !important; 
  line-height: 165% !important;
  letter-spacing: $letterspacing-sm !important;
}

h1 {
  @extend .type-header-1;
}

h2 {
  @extend .type-header-2;
}

h3 {
  @extend .type-header-3;
}

h4 {
  @extend .type-header-4;
}

p {
  @extend .type-body-1;
}

li {
  @extend .type-body-1;
}

small {
  @extend .type-body-3;
}
