@import '../../vars';
@import '../../typography.scss';

.candidateExcerpt {
  text-align: center;
  &_hed {
    text-decoration: underline;
  }
  &_body {
    padding: 0px 15px;
  }
  &_img {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    max-width: 150px;
    max-height: 150px;
    @media screen and (min-width: 768px) {
      max-width: 200px;
      max-height: 200px;
    }
    @media screen and (min-width: 992px){
      max-width: 250px;
      max-height: 250px;
    }
  }
  &_imgWrapper {
    justify-content: center;
  }

  a:last-of-type {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: $yellow;
    >p {
    @extend .type-label-1;
    }
  }
  
}