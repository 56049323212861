@import '../../_vars';
@import '../../typography.scss';

@mixin simpleContrastBtn {
  @extend .type-label-1;
  background-color: $light;
  color: $dark;
  &:hover {
    color: $light;
    background-color: $dark; 
  }
  border: 1px solid $dark;
}

#nominate {
  button, .nominationWizard>a {
    @include simpleContrastBtn();
  }
  form {
    display: grid;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 20px 1fr;
    }
    input {
      border: 1px solid $dark;
    }
    button {
      grid-column: initial;
      grid-row: 2;
      margin-top: 20px;
      @media screen and (min-width: 768px) {
        margin-top: initial;
        grid-row: 1;
        grid-column: 3/4;
      }
    }
    p {
      @extend .type-body-2;
      color: $red;
      grid-column: 1/4;
    }
  }
  .delegateQuestion {
    display: grid;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 20px 1fr;
    }
    p {
      grid-column: 1/4;
    }
    a {
      @include simpleContrastBtn();
      // grid-row: 3;
      align-self: center;
      justify-self: center;
      @media screen and (min-width: 768px) {
        grid-row: 3;
      }
    }
    a:first-of-type {
      grid-column: 1;
      grid-row: 3;
    }
    a:last-of-type {
      grid-column: 1;
      grid-row: 4;
      margin-top: 10px !important;
      @media screen and (min-width: 768px) {
        margin-top: initial !important;
        grid-column: 3;
        grid-row: 3;
      }
    }
  }
  .meetingPassed a {
    color: $red;
    &:hover {
      color: $red;
    }
  }
  .hide-large {
    display: none;
    @media screen and (min-width: 992px) {
      display: block;
  }
}

}

.backLink {
  @extend .type-label-1;
  display: block;
  color: $red;
  width: 350px;
  float: left; 
  &:hover {
    color: $red;
  }
}

.prefLink {
  @extend .type-body-1;
  display: block;
  color: $red;
  float: left; 
  &:hover {
    color: $red;
  }
}

Table {
  thead {
    tr {
      th {
        font-size: 21px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 19px;
      }
    }
  }
}
